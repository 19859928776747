<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" :gutter="8" class="row-bg">
        <el-col :span="6">
          <el-input
            placeholder="请输入商家名称/商品名称"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button v-if="type == 1" @click="newBtn" icon="el-icon-plus"
            >添加商品</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        height="calc(100vh - 220px)"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="商家名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.merchantName }}</template>
        </el-table-column>
        <!-- <el-table-column label="商家logo" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
            <el-image :src="scope.row.merchantLogo" :preview-src-list="[scope.row.merchantLogo]"></el-image>
            </template>
          </el-table-column> -->

        <el-table-column label="商品名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="商品分类" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryName }}</template>
        </el-table-column>
        <el-table-column
          label="商品封面图"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-image
              :src="scope.row.productLogo"
              :preview-src-list="[scope.row.productLogo]"
            ></el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column label="商品图" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image v-if="scope.row.bannerUrl" :preview-src-list="scope.row.bannerUrl.split(',')"
              :src="scope.row.bannerUrl.split(',')[0]"></el-image>
            </template>
          </el-table-column> -->
        <!-- <el-table-column label="详情图" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.detailUrl }}</template>
          </el-table-column> -->
        <el-table-column label="价格" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="单位" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.unit }}</template>
        </el-table-column>
        <el-table-column label="商品数量" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.productNum }}</template>
        </el-table-column>
        <el-table-column label="发货地址" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.address }}</template>
        </el-table-column>
        <el-table-column
          label="是否极速配送"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.isDelivery }}</template>
        </el-table-column>
        <el-table-column label="已售数量" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.saleNum }}</template>
        </el-table-column>
        <el-table-column label="标签" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.label }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDt }}</template>
        </el-table-column>
        <el-table-column
          label="创建人名称"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.createName }}</template>
        </el-table-column>
        <el-table-column label="排序" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.seq }}</template>
        </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small"
              >编辑</el-button
            >
            <!-- <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="720px"
    >
      <el-form ref="form" :model="form" label-width="90px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="商品分类：" prop="categoryName">
              <el-select style="width: 210px" v-model="form.categoryCode">
                <el-option
                  :label="item.categoryName"
                  :value="item.categoryCode"
                  v-for="(item, index) in options"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品名称：" prop="productName">
              <el-input
                style="width: 210px"
                v-model="form.productName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品价格：" prop="price">
              <el-input style="width: 120px" v-model="form.price">
                <span slot="append">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品图片：" prop="price">
              <el-upload
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :on-success="handleSuccess"
                :action="$uploadURL"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                class="avatar-uploader"
              >
                <div v-if="form.productLogo" class="flex">
                  <div style="margin: 5px" class="imgBox">
                    <img :src="form.productLogo" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="openElImageViewer(form.productLogo)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="
                          handelRemove(form.productLogo, 'productLogo')
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <!-- <el-upload
                :action="$uploadURL"
                :limit="1"
                :on-success="handleSuccess"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品详情：">
              <el-upload
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :on-success="handleSuccessDetail"
                :action="$uploadURL"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemoveDetail"
                class="avatar-uploader"
              >
                <div v-if="form.detailUrl" class="flex">
                  <div style="margin: 5px" class="imgBox">
                    <img :src="form.detailUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="openElImageViewer(form.detailUrl)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelRemove(form.detailUrl, 'detailUrl')"
                      ></i>
                    </div>
                  </div>
                </div>
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <!-- <el-upload
                :action="$uploadURL"
                :limit="1"
                :on-success="handleSuccessDetail"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemoveDetail"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
      <el-dialog append-to-body :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-dialog>

    <ElImageViewer
      v-if="showElImageViewer"
      :zIndex="8000"
      :src="Elimgs"
      :on-close="handleCloseElImageViewer"
      :url-list="[Elimgs]"
    ></ElImageViewer>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer },
  data() {
    return {
      type: JSON.parse(localStorage.getItem("userInfo")).userInfo.type,
      dialogVisible: false,
      dialogImageUrl: "",
      options: [],
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 20,
        pageCount: 1,
        currPage: 1,
        condition: {
          merchantCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
            .objectCode,
        },
      },
      form: {
        merchantCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .objectCode,
        merchantName: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .objectName,
        categoryCode: "",
        categoryName: "",
        productName: "",
        productLogo: "",
        price: "",
        detailUrl: "",
      },
      showDialog: false,
      tableData: [],
      Elimgs: "",
      showElImageViewer: false,
    };
  },

  created() {
    this.getList();
    this.getOptions();
  },

  methods: {
    handleSuccess(res) {
      if (res.code == 200) {
        this.form.productLogo = res.data.url;
      } else {
        this.$message.error(res.message);
      }
    },
    handleSuccessDetail(res) {
      if (res.code == 200) {
        this.form.detailUrl = res.data.url;
      } else {
        this.$message.error(res.message);
      }
    },
    handleRemoveDetail(file, fileList) {
      this.form.detailUrl = "";
    },
    handleRemove(file, fileList) {
      this.form.productLogo = "";
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async getOptions() {
      let res = await this.$http.post("productCategory/queryListByMerchant", {
        merchantCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .objectCode,
      });
      if (res.data.code == 200) {
        this.options = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/merchantProduct/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 查看
    openElImageViewer(item) {
      this.Elimgs = item;
      this.showElImageViewer = true;
    },
    // 关闭
    handleCloseElImageViewer() {
      this.showElImageViewer = false;
    },
    //删除
    handelRemove(item, type) {
      if (type === "productLogo") {
        return (this.form.productLogo = "");
      }
      if (type === "detailUrl") {
        return (this.form.detailUrl = "");
      }
    },
    // 保存
    handleSave() {
      var that = this;
      let data = JSON.parse(JSON.stringify(this.form));
      if (data.categoryCode == "") {
        this.$message.info("请选择商品分类");
        return;
      }
      let index = this.options.findIndex(
        (item) => item.categoryCode == data.categoryCode
      );
      if (index != -1) {
        data.categoryName = this.options[index].categoryName;
      }
    const url = this.labelType === "add" ? "/merchantProduct/addProduct" : "/merchantProduct/update";
              console.log(this.labelType, url );

      that.$http
        .post(url, data)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {});
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },
    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/merchantProduct/delete", { id: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {
        merchantCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .objectCode,
        merchantName: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .objectName,
        categoryCode: "",
        categoryName: "",
        productName: "",
        productLogo: "",
        price: "",
        detailUrl: "",
      };
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #409eff;
  cursor: pointer;
}
/deep/.el-table__fixed {
  height: calc(100% - 8px) !important;
}
/deep/ .el-upload--picture-card {
  height: 100px;
  width: 100px;
  line-height: 100px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  height: 100px;
  width: 100px;
  line-height: 100px;
}

.img-icon {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 110px;
  height: 100px;
  border: 1px solid #ccc;
  // margin-left: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 6px;
}

.imgBox {
  //width: 100px;
  height: 100px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}
</style>
